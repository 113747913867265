import { Component, OnInit, inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BaseComponent } from '../../../common/components/base.component'
import { AuthService } from '../../../common/services/auth.service'
import { AppStateService } from '../../../common/store/state/app/app.state.service'
import { SpinnerService } from '../../../common/services/spinner.service'
import { catchError, finalize, of, tap } from 'rxjs'
import { LoginFlowStateService } from '../../../common/store/state/login-flow/login-flow.state.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent extends BaseComponent implements OnInit {

  #authService = inject(AuthService)
  #loginFlowStateService = inject(LoginFlowStateService)
  #appStateService = inject(AppStateService)
  #spinnerService = inject(SpinnerService)


  loginForm: FormGroup
  showPassword: boolean
  invalidLogin: string

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      pass: new FormControl('', [Validators.required])
    })

  }

  forgotPassword() {
    // TODO: Add forgot password logic
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword
  }

  async login() {
    this.#spinnerService.setLoading(true, 'test')
    if (!this.#appStateService.getDevice()) {
      await this.#authService.initDevice()
    }

    this.subscriptions.add(this.#authService.initAuthInformationAndLogin(this.loginForm.value)
      .pipe(tap(() => {
        this.#navigate()
      }), catchError((httpErrorResp) => {
        console.log(httpErrorResp)
        this.invalidLogin = httpErrorResp.error
        return of(httpErrorResp)
      }), finalize(() => {
        this.#spinnerService.setLoading(false, 'test')
      })).subscribe())
  }

  // decide precedence based on login flow
  #navigate() {
    const loginFlow = this.#loginFlowStateService.getLoginFlow()
    if (loginFlow.DoForcedPasswordReset) {
      this.router.navigate(['/reset-password/create-new'])
    } else if (loginFlow.Do2FaSetup) {
      this.router.navigate(['/mfa/collection/email'])
    } else if (loginFlow.Do2FaCheck) {
      this.router.navigate(['/mfa/selection'])
    } else if (loginFlow.DoSecurityQandA) {
      this.router.navigate(['/security-qa'])
    } else {
      this.router.navigate(['/welcome'])
    }
  }

}